"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'FrkFlexContainer',
    props: {
        direction: { default: 'row' },
        gap: { default: 2 },
        items: { default: 'center' },
        justify: { default: 'start' }
    },
    setup(__props) {
        const props = __props;
        const flexGapClass = (0, vue_2.computed)(() => `tw-gap-${props.gap}`);
        const flexItems = (0, vue_2.computed)(() => `tw-items-${props.items}`);
        const flexJustify = (0, vue_2.computed)(() => `tw-justify-${props.justify}`);
        const flexDirection = (0, vue_2.computed)(() => (props.direction === 'column' ? 'tw-flex-col' : 'tw-flex-row'));
        return { __sfc: true, props, flexGapClass, flexItems, flexJustify, flexDirection };
    }
});
