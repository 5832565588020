"use strict";
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.acceptTermsMutation = exports.AcceptTermsDocument = void 0;
const graphql_vue_apollo_patch_1 = require("@freelancerepublik/graphql-vue-apollo-patch");
const vue_apollo_smart_ops_1 = require("vue-apollo-smart-ops");
exports.AcceptTermsDocument = (0, graphql_vue_apollo_patch_1.gql) `
    mutation acceptTerms {
  acceptTerms {
    _id
    termsList
  }
}
    `;
/**
 * __acceptTermsMutation__
 *
 * To run a mutation, you call `acceptTermsMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = acceptTermsMutation(this, {
 *   variables: {},
 * });
 */
exports.acceptTermsMutation = (0, vue_apollo_smart_ops_1.createMutationFunction)(exports.AcceptTermsDocument);
