"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OPPORTUNITY_SHARE_DESTINATIONS = exports.SELECTIVE_BUSINESS_MAX_LIMIT = exports.SEARCH_KANBAN_STATUS_TUPLES = exports.areFreelanceExpectedContractAndOpportunityTypeCompatible = exports.MISSION_TYPE_OPTIONS = exports.getClientPriceFromFreelancePrice = exports.getFreelancePriceFromClientPrice = exports.FRK_MARGIN = exports.MISSION_PRIORITY_OPTIONS = exports.MISSION_DURATION_OPTIONS = exports.PROFILE_LEVEL_OPTIONS = void 0;
const helpers_1 = require("@freelancerepublik/helpers");
const graphql_types_1 = require("@freelancerepublik/graphql-types");
const KeySkill_core_1 = require("./KeySkill.core");
// ? Experimental : use the label as value
exports.PROFILE_LEVEL_OPTIONS = KeySkill_core_1.SELECTION_KEY_SKILLS_LEVEL_OPTIONS.filter(level => level.value !== graphql_types_1.SelectionKeySkillsLevel.Level_0).map(level => ({
    ...level,
    value: level.label,
}));
exports.MISSION_DURATION_OPTIONS = [
    { value: graphql_types_1.MissionDuration.None, label: 'Non fixée' },
    { value: graphql_types_1.MissionDuration.LessThanThreeMonths, label: '- de 3 mois' },
    { value: graphql_types_1.MissionDuration.BetweenThreeAndSixMonths, label: '3 à 6 mois' },
    { value: graphql_types_1.MissionDuration.BetweenSixAndTwelveMonths, label: '6 à 12 mois' },
    { value: graphql_types_1.MissionDuration.MoreThanTwelveMonths, label: '+ de 12 mois' },
];
exports.MISSION_PRIORITY_OPTIONS = [
    { value: graphql_types_1.MissionPriorityEnum.Priority_0, label: 'PRIO 0', default: true },
    { value: graphql_types_1.MissionPriorityEnum.Priority_1, label: 'PRIO 1' },
    { value: graphql_types_1.MissionPriorityEnum.Priority_2, label: 'PRIO 2' },
    { value: graphql_types_1.MissionPriorityEnum.Priority_3, label: 'PRIO 3' },
];
// Marge cible de 18% pour les missions
exports.FRK_MARGIN = 0.18;
function getFreelancePriceFromClientPrice(price) {
    return (0, helpers_1.roundXToNDecimal)(price * (1 - exports.FRK_MARGIN), 2);
}
exports.getFreelancePriceFromClientPrice = getFreelancePriceFromClientPrice;
// We want nice rounded numbers multiple of 5 for the client
function getClientPriceFromFreelancePrice(price) {
    return (0, helpers_1.roundXToNDecimal)(price / (1 - exports.FRK_MARGIN), 2);
}
exports.getClientPriceFromFreelancePrice = getClientPriceFromFreelancePrice;
exports.MISSION_TYPE_OPTIONS = [
    { value: graphql_types_1.OpportunityType.Cdi, label: 'CDI' },
    { value: graphql_types_1.OpportunityType.Freelancing, label: 'Freelancing' },
];
function areFreelanceExpectedContractAndOpportunityTypeCompatible(freelanceExpectedContract, opportunityType) {
    if (opportunityType === graphql_types_1.OpportunityType.Cdi) {
        return [graphql_types_1.ExpectedContract.Cdi, graphql_types_1.ExpectedContract.FreelanceOrCdi, graphql_types_1.ExpectedContract.Nc].includes(freelanceExpectedContract);
    }
    return [graphql_types_1.ExpectedContract.Freelance, graphql_types_1.ExpectedContract.FreelanceOrCdi, graphql_types_1.ExpectedContract.Nc].includes(freelanceExpectedContract);
}
exports.areFreelanceExpectedContractAndOpportunityTypeCompatible = areFreelanceExpectedContractAndOpportunityTypeCompatible;
exports.SEARCH_KANBAN_STATUS_TUPLES = [
    [graphql_types_1.MissionStatusEnum.SearchActive, graphql_types_1.SelectionStatus.Selected, 'Sendouts', 'Pas encore de sendout'],
    [graphql_types_1.MissionStatusEnum.PropositionActive, graphql_types_1.SelectionStatus.PendingClient, 'Propositions', 'Pas de proposition'],
    [graphql_types_1.MissionStatusEnum.InterviewActive, graphql_types_1.SelectionStatus.Interview, 'Entretien', "Pas d'entretien"],
    [graphql_types_1.MissionStatusEnum.DealBefore, graphql_types_1.SelectionStatus.Ok, 'Runner', 'Pas de runner ?!'],
    [graphql_types_1.MissionStatusEnum.DealOngoing, graphql_types_1.SelectionStatus.Ok, 'Runner', 'Pas de runner ?!'],
];
// ? Voir les commentaires https://www.notion.so/Ta-en-bas-de-la-liste-des-recommandations-je-peux-cliquer-sur-un-bouton-j-en-veux-plus-3-fois-a-1b45ebd0ed57489c81d822d02a1504ec?d=ddd390bd463e47b18dc924b5fe6eb08d
exports.SELECTIVE_BUSINESS_MAX_LIMIT = 100;
exports.OPPORTUNITY_SHARE_DESTINATIONS = [
    { label: 'Slack', value: 'slack', checked: true },
    { label: 'TurnOver IT', value: 'turnover', checked: false },
];
