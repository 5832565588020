import Vue from 'vue';
import Vuex from 'vuex';

import forms from '@freelancerepublik/commons/ui/store/forms';
import { initVuexStore } from '@freelancerepublik/commons/ui/store/initVuexStore';
import messages from '@freelancerepublik/commons/ui/store/messages';

import firstUse from './firstUse';

// Vue.use(Vuex) must be called before creating a store instance
Vue.use(Vuex);

const vuexModules = {
  firstUse,
  forms,
  messages,
};
const vuexPersistModules = ['firstUse'];
const vuexStore = initVuexStore(Vuex, vuexModules, vuexPersistModules);

export default vuexStore;
