"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasAcceptedLatestTerms = exports.getLatestTerms = exports.TERMS_OF_CONTRACT_LATEST_UPDATE = void 0;
const helpers_1 = require("@freelancerepublik/helpers");
exports.TERMS_OF_CONTRACT_LATEST_UPDATE = '2023-11-30';
function getLatestTerms(termsList) {
    const latestTerms = termsList[termsList.length - 1];
    return latestTerms;
}
exports.getLatestTerms = getLatestTerms;
function hasAcceptedLatestTerms(termsList) {
    const latestTerms = getLatestTerms(termsList);
    return latestTerms ? helpers_1.dateUtils.isDateAfter(latestTerms, exports.TERMS_OF_CONTRACT_LATEST_UPDATE) : false;
}
exports.hasAcceptedLatestTerms = hasAcceptedLatestTerms;
